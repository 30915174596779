import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

const Footer = () => {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>

Suivez-nous sur nos réseaux sociaux
</span>
        </div>

        <div>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="google" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a>
          
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                Espace Balard
              </h6>
              <p>
                

A deux pas du métro Lourmel, dans le 15ème arrondissement de Paris, notre studio vous accueille dans un bel espace de 80 m2, calme et lumineux pour pratiquer la danse, le yoga ou le théâtre auprès de professeurs enthousiastes et compétents...

              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Activités</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Adulte
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Enfant
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Anniversaire
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Galerie</h6>
              <p>
                <a href='#!' className='text-reset'>
                  PHOTO
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  VIDEO
                </a>
              </p>
              <p>
               
              </p>
              <p>
                
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                354 Rue Lecourbe , Paris, 75015
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                espacebalard@gmail.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> 06 66 84 86 79
              </p>
              
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright :
        <a className='text-reset fw-bold' >
          ESPACE BALARD
        </a>
      </div>
    </MDBFooter>
  );
}

export default Footer;
