import React from 'react';
import '../styles/Tarif.css';
import img1 from '../Images/Dance enfants.jpg';

import img2 from '../Images/theater.jpg';
import img3 from '../Images/chant.jpg';
import img4 from '../Images/salsa.jpg';
import img5 from '../Images/chant.jpg';
import img6 from '../Images/jazz.jpg';
import img7 from '../Images/YOGA.jpg';
import img8 from '../Images/YOGA.jpg';
import img9 from '../Images/YOGA.jpg';
import img10 from '../Images/theater.jpg';





const Tarif = ({ imgSrc, title, subtitle, price }) => (
  <div className="col-md-4">
    <div className="card card-shadow border-0 mb-4">
      <img className="card-img-top" src={imgSrc} alt={title} />
      <div className="p-3">
        <h5 className="font-weight-medium mb-0">{title}</h5>
        <h6 className="subtitle font-13">{subtitle}</h6>
        <ul className="list-inline font-14 mt-3">
          <li className="py-1"><i className="icon-check text-success"></i> 1 Séance par semaine </li>
          <li className="py-1"><i className="icon-check text-success"></i> Formateur dédié attribué </li>
        </ul>
        <div className="d-flex mt-3 align-items-center">
          <h2 className="price">{price}<small>/ANS</small></h2>
          <div className="ml-auto">
            <a className="btn btn-danger-gradiant rounded-pill text-white btn-md border-0" href="/Schedule">S'inscrie</a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Pricing = () => {
  const pricingData = [
    { imgSrc: img1, title: 'Danse Enfants ', subtitle: 'Adil', price: '520 €' },
    { imgSrc: img2, title: 'Théâtre Enfants', subtitle: 'Zakia', price: '520 €' },
    { imgSrc: img3, title: 'Chant Enfants', subtitle: 'Adil', price: '520 €' },
    { imgSrc: img4, title: 'Danse Adulte', subtitle: 'Loundjah', price: '520 €' },
    { imgSrc: img5, title: 'Chant Adulte', subtitle: 'Loundjah', price: '520 €' },
    { imgSrc: img6, title: 'Danse moderne JAZZ Adulte', subtitle: 'Julie', price: '520' },
    { imgSrc: img7, title: 'YOGA Adulte ', subtitle: 'Annick', price: '520 €' },
    { imgSrc: img8, title: 'Gym Douce Adulte', subtitle: 'Ludovic', price: '520 €' },
    { imgSrc: img9, title: 'Barre Au Sol Adulte', subtitle: 'Ludovic', price: '520 €' },
    { imgSrc: img10, title: 'Théâtre Adulte', subtitle: 'Zakia', price: '520 €' },
  ];

  return (
    <div className="pricing4 py-5 bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h3 className="mb-3">Abonnement annuel </h3>
            <h6 className="subtitle font-weight-normal">Vous pouvez compter sur notre liste de fonctionnalités incroyables et nos services clients seront sans aucun doute une expérience formidable pour vous.</h6>
          </div>
        </div>
        <div className="row mt-4">
          {pricingData.map((data, index) => (
            <Tarif key={index} {...data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;

