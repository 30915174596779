import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import '../styles/Payement.css'; // Import the CSS file

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const pricingOptions = [
  { label: 'Carte 10 cours 200 €', value: 'price_1PVtLMGmu9hQ5dji5HFoLxnj', imgSrc: 'path/to/your/image1.jpg' },
  { label: 'Cours individuel 22 €', value: 'price_1PVtMXGmu9hQ5djiqBdlc1bH', imgSrc: 'path/to/your/image2.jpg' },
  { label: 'Abonnement 1 cours/semaine annuel 520€', value: 'price_1PVtN9Gmu9hQ5dji9r6RrfE3', imgSrc: 'path/to/your/image3.jpg' },
];

const PaymentOption = ({ label, imgSrc, onClick }) => (
  <div className="col-md-4">
    <div className="card card-shadow border-0 mb-4">
      <div className="p-3">
        <h5 className="font-weight-medium mb-0">{label}</h5>
        <div className="d-flex mt-3 align-items-center">
          <div className="ml-auto">
            <button className="btn btn-danger-gradiant rounded-pill text-white btn-md border-0" onClick={onClick}>
              choisir
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Payment = () => {
  const handleClick = async (priceId) => {
    const stripe = await stripePromise;

    try {
      const response = await axios.post('https://us-central1-espace-balard-98a3e.cloudfunctions.net/createCheckoutSession', { priceId });

      const { id } = response.data;
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
      if (error) {
        console.error("Stripe Checkout error:", error);
      }
    } catch (error) {
      console.error("Payment error:", error);
    }
  };

  return (
    <div className="pricing4 py-5 bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h3 className="mb-3">Sélectionnez un plan d'abonnement</h3>
            <h6 className="subtitle font-weight-normal">Choisissez le forfait le mieux adapté à vos besoins et procédez au paiement.</h6>
          </div>
        </div>
        <div className="row mt-4">
          {pricingOptions.map((option, index) => (
            <PaymentOption key={index} {...option} onClick={() => handleClick(option.value)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Payment;
