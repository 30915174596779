import React from 'react';
import '../styles/Category.css';
import '../styles/type.css';
import '../styles/Gallery.css';
import modernJazzVideo from '../Videos/1.mp4';
import sampleImage from '../Images/s1.jpg'; // Update the path to the image
import sampleImage2 from '../Images/s2.jpg'; // Update the path to the image
import newImage from '../Images/birthday.jpg'; // Update the path to the image
import service26Image1 from '../Images/s3.jpg'; // Add this line
import service26Image2 from '../Images/jazz6.jpg'; // Add this line
import galleryImage1 from '../Images/s4.jpg'; // Update the path as needed
import galleryImage2 from '../Images/s5.jpg'; // Update the path as needed
import galleryImage3 from '../Images/s6.jpg'; // Update the path as needed
import galleryImage4 from '../Images/s7.jpg'; // Update the path as needed
import galleryImage5 from '../Images/s1.jpg'; // Update the path as needed

const Studio = () => {
  return (
    <div className="category-page">
      <section className="hero-section">
        <video className="hero-video" autoPlay muted loop>
          <source src={modernJazzVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-overlay">
          <h4 className="hero-title">Salle de Danse Événementielle à Paris 15 : Votre Espace Créatif Privé ! </h4>
          <p className="hero-description"></p>
        </div>
      </section>
      <div className="py-5 service-12">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="my-3">UNE BELLE SALLE A PRIVATISER</h4>
              <h6 className="font-weight-light subtitle">Salle de Danse Événementielle à Paris 15 : Votre Espace Créatif Privé ! 🎶</h6>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <h6 className="font-weight-medium">Emplacement privilégié</h6>
                  <p>🌟 Nichée au cœur de Paris 15, notre belle salle offre un espace de 80m2 en rez-de-chaussée, facilitant l’accès pour tous.</p>
                </div>
                <div className="col-md-6 mt-3">
                  <h6 className="font-weight-medium">Adaptabilité créative</h6>
                  <p>🌟 Que ce soit pour des répétitions, des castings, des workshops, des stages ou des cours particuliers, nous ajustons notre espace pour répondre à vos attentes.</p>
                </div>
                <div className="col-md-6 mt-3">
                  <h6 className="font-weight-medium">Confort optimal</h6>
                  <p>🌟 Notre sol de tapis de danse et le système d’aération garantissent un environnement parfait pour votre créativité.</p>
                </div>
                <div class="col-md-6 mt-3">
                  <h6 class="font-weight-medium">Facilités incluses</h6>
                  <p>🌟 Vestiaires, sanitaires et propreté impeccable sont au rendez-vous.</p>
                </div>
                <div className="col-lg-12 my-4">
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row wrap-service12">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12 img-hover mb-4">
                      <img src={sampleImage} className="rounded img-shadow img-fluid" alt="Studio" />
                    </div>
                    <div className="col-md-12 img-hover mb-4">
                      <img src={sampleImage2} className="rounded img-shadow img-fluid" alt="Studio" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 uneven-box">
                  <div className="row">
                    <div className="col-md-12 img-hover mb-4">
                    </div>
                    <div className="col-md-12 img-hover mb-4">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Reservation Section */}
      <div className="py-5 service-26">
        <div className="container">
          <div className="row wrap-service-26">
            <div className="col-md-7 align-self-center">
              <div className="max-box">
                <h3 className="mt-3">Comment réserver ?</h3>
                <p className="mt-3">📅 Planifiez à l’avance : Nous vous demandons de bien vouloir prévoir un délai minimum de 48 heures ouvrées pour vos réservations.</p>
                <p>📧 Contactez-nous : Pour toute demande de location ponctuelle ou régulière, envoyez-nous un e-mail à espacebalard@gmail.com en précisant :</p>
                <ul>
                  <li>Le type d’événement (cours, stage, répétition, casting, etc.).</li>
                  <li>La discipline artistique.</li>
                  <li>Dates et horaires souhaités.</li>
                  <li>Nombre estimé de participants.</li>
                  <li>Coordonnées du responsable.</li>
                </ul>
                <p>🤝 Nous prônons la convivialité et le respect du voisinage. Évitez le bruit dans les espaces communs et dans la cour.</p>
                <p>🕒 Respectez les horaires : La ponctualité contribue à une expérience harmonieuse pour tous.</p>
                <p>🧹 Laissez aucun trace : Remettez la salle en ordre, en jetant les déchets dans les poubelles prévues.</p>
                <p>🚫 Sécurité de vos affaires : Nous ne pouvons être tenus responsables des pertes, oublis ou vols.</p>
                <p>👟 Propreté et hygiène : Changez de chaussures à l’entrée, évitez nourriture, alcool et chewing-gums, et n’utilisez pas de bougies ou d’encens.</p>
              </div>
            </div>
            <div className="col-md-5">
              <img src={service26Image1} className="img-fluid" alt="Studio" />
            </div>
          </div>
          <div className="row wrap-service-26 mt-4 pt-3">
            <div className="col-md-6">
            </div>
          
          </div>
        </div>
      </div>

     
      <div class="cards">
        <div class="content content-2">
          <div class="fab fa-instagram"></div>
          <h2>
            Instagram
          </h2>
          <p>
            Découvrez le monde visuel vibrant de l'Espace Balard sur Instagram. Suivez-nous pour des photos magnifiques, des aperçus des coulisses et du contenu créatif qui met en valeur notre atmosphère unique et nos événements. Soyez inspiré par nos histoires et connectez-vous avec nous à travers vos clichés préférés
          </p>
          <a href="Schedule">Read More</a>
        </div>
        <div class="content content-3">
          <div class="fab fa-youtube"></div>
          <h2>
            Youtube
          </h2>
          <p>
            Plongez dans le contenu vidéo dynamique de l'Espace Balard sur YouTube. Regardez des vidéos captivantes, des temps forts d'événements, des tutoriels et bien plus encore. Abonnez-vous à notre chaîne pour ne rien manquer de nos dernières publications et découvrez tout ce que nous avons à offrir.
          </p>
          <a href="Schedule">Read More</a>
        </div>
      </div>
    </div>
  );
};

export default Studio;
