import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

const Header = () => {
  useEffect(() => {
    const menuButton = document.querySelector('.menu-button');
    const sideMenu = document.querySelector('.side-menu');
    const closeBtn = document.querySelector('.close-btn');
    const menuLinks = document.querySelectorAll('.side-menu a');

    const toggleMenu = () => {
      sideMenu.classList.toggle('show');
    };

    const closeMenu = (e) => {
      if (!sideMenu.contains(e.target) && e.target !== menuButton) {
        sideMenu.classList.remove('show');
      }
    };

    const hideMenu = () => {
      sideMenu.classList.remove('show');
    };

    menuButton.addEventListener('click', toggleMenu);
    closeBtn.addEventListener('click', toggleMenu);
    document.addEventListener('click', closeMenu);
    menuLinks.forEach(link => {
      link.addEventListener('click', hideMenu);
    });

    return () => {
      menuButton.removeEventListener('click', toggleMenu);
      closeBtn.removeEventListener('click', toggleMenu);
      document.removeEventListener('click', closeMenu);
      menuLinks.forEach(link => {
        link.removeEventListener('click', hideMenu);
      });
    };
  }, []);

  return (
    <>
      <header className="header">
        <div className="logo">
          <h1>Espace Balard 15e</h1>
        </div>
        <nav>
          <button className="menu-button">&#9776;</button>
          <ul className="nav-links">
            <li><Link to="/">Accueil</Link></li>
            <li><Link to="/Schedule">Planning</Link></li>
            <li><Link to="/Tarif">Tarif</Link></li>
            <li><Link to="/Team">Nos professeurs</Link></li>
            <li className="dropdown">
              <button className="dropdown-button">
                Activités
              </button>
              <ul className="dropdown-menu">
                <li><Link to="/adulte">Adulte</Link></li>
                <li><Link to="/enfant">Enfants</Link></li>
              </ul>
            </li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/login">Espace Client</Link></li>
          </ul>
        </nav>
      </header>
      <div className="side-menu">
        <span className="close-btn">&times;</span>
        <ul>
          <li><Link to="/">Accueil</Link></li>
          <li><Link to="/Schedule">Planning</Link></li>
          <li><Link to="/Tarif">Tarif</Link></li>
          <li><Link to="/Team">Nos professeurs</Link></li>
          <li><Link to="/adulte">Adulte</Link></li>
          <li><Link to="/enfant">Enfants</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li><Link to="/login">Espace Client</Link></li>
        </ul>
      </div>
    </>
  );
};

export default Header;
