import React, { useState } from 'react';
import '../styles/Contact.css';
import mapImage from '../Images/darkmap.png'; // Adjust the path to where you placed the image
import { db } from '../components/firebase'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore';

const Contact = () => {
    const originalImageUrl = "https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/1.jpg";

    const [formData, setFormData] = useState({
        nom: '',
        email: '',
        téléphone: '',
        adresse: '',
        message: ''
    });

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, 'contacts'), {
                ...formData,
                createdAt: new Date()
            });
            setSuccess('Message envoyé avec succès!');
            setError('');
            // Clear form after successful submission
            setFormData({
                nom: '',
                email: '',
                téléphone: '',
                adresse: '',
                message: ''
            });
        } catch (err) {
            setError('Erreur lors de l\'envoi du message.');
            setSuccess('');
        }
    };

    return (
        <div className="contact2" style={{ backgroundImage: `url(${mapImage})` }} id="contact">
            <div className="container">
                <div className="row contact-container">
                    <div className="col-lg-12">
                        <div className="card card-shadow border-0 mb-4">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="contact-box p-4">
                                        <h4 className="title">Contactez Nous</h4>
                                        {success && <p className="success-message">{success}</p>}
                                        {error && <p className="error-message">{error}</p>}
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mt-3">
                                                        <input 
                                                            className="form-control" 
                                                            type="text" 
                                                            name="nom" 
                                                            placeholder="nom" 
                                                            value={formData.nom} 
                                                            onChange={handleChange} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mt-3">
                                                        <input 
                                                            className="form-control" 
                                                            type="text" 
                                                            name="email" 
                                                            placeholder="email" 
                                                            value={formData.email} 
                                                            onChange={handleChange} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mt-3">
                                                        <input 
                                                            className="form-control" 
                                                            type="text" 
                                                            name="téléphone" 
                                                            placeholder="téléphone" 
                                                            value={formData.téléphone} 
                                                            onChange={handleChange} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mt-3">
                                                        <input 
                                                            className="form-control" 
                                                            type="text" 
                                                            name="adresse" 
                                                            placeholder="Adresse" 
                                                            value={formData.adresse} 
                                                            onChange={handleChange} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mt-3">
                                                        <input 
                                                            className="form-control" 
                                                            type="text" 
                                                            name="message" 
                                                            placeholder="message" 
                                                            value={formData.message} 
                                                            onChange={handleChange} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <button type="submit" className="btn btn-danger-gradiant mt-3 mb-3 text-white border-0 py-2 px-3">
                                                        <span> SOUMETTRE <i className="ti-arrow-right"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-4 bg-image" style={{ backgroundImage: `url(${originalImageUrl})` }}>
                                    <div className="detail-box p-4">
                                        <h5 className="text-white font-weight-light mb-3">ADRESSE</h5>
                                        <p className="text-white op-7">354 Rue Lecourb<br />Paris 75015</p>
                                        <h5 className="text-white font-weight-light mb-3 mt-4">CONTACTEZ-NOUS</h5>
                                        <p className="text-white op-7">06 66 86 84 79</p>
                                        <div className="round-social light">
                                            <a href="#" className="ml-0 text-decoration-none text-white border border-white rounded-circle"><i className="icon-social-facebook"></i></a>
                                            <a href="#" className="text-decoration-none text-white border border-white rounded-circle"><i className="icon-social-twitter"></i></a>
                                            <a href="#" className="text-decoration-none text-white border border-white rounded-circle"><i className="icon-social-youtube"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;

