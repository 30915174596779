import React from 'react';
import '../styles/SlotDetailsModal.css';

function SlotDetailsModal({ slot, onClose }) {
  if (!slot) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>{slot.course}</h2>
        <p><strong>Time:</strong> {slot.time}</p>
        <p><strong>Duration:</strong> {slot.duration}</p>
        <p><strong>Instructor:</strong> {slot.instructor}</p>
        <p><strong>Description:</strong> {slot.description}</p>
      </div>
    </div>
  );
}

export default SlotDetailsModal;

