import React from 'react';
import '../styles/Category.css';
import '../styles/type.css';
import '../styles/Gallery.css';
import modernJazzVideo from '../Videos/theatrenfant.mp4';
import sampleImage from '../Images/kidthe1.jpg'; // Update the path to the image
import sampleImage2 from '../Images/kidthe2.jpeg'; // Update the path to the image
import newImage from '../Images/kidthe1.jpg'; // Update the path to the image
import service26Image1 from '../Images/kidthe1.jpg'; // Add this line
import service26Image2 from '../Images/kidthe2.jpeg'; // Add this line
import galleryImage1 from '../Images/jazz.jpg'; // Update the path as needed
import galleryImage2 from '../Images/birth.jpg'; // Update the path as needed
import galleryImage3 from '../Images/salsa.jpg'; // Update the path as needed
import galleryImage4 from '../Images/danse gregoire.png'; // Update the path as needed
import galleryImage5 from '../Images/danse gregoire.png'; // Update the path as needed
import galleryImage6 from '../Images/Adil-Djibouli-3973-scaled-1-150x150.jpg'; // Update the path as needed
import galleryImage7 from '../Images/Anne-sophie-150x150.jpg'; // Update the path as needed
import galleryImage8 from '../Images/birth.jpg'; // Update the path as needed

const Atheliertheatreenfant = () => {
  return (
    <div className="category-page">
    <section className="hero-section">
      <video className="hero-video" autoPlay muted loop>
        <source src={modernJazzVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-overlay">
  <h1 className="hero-title">Atelier Théâtre Enfants</h1>
  <p className="hero-description">Découvrez la magie du théâtre pour les enfants.</p>
</div>
</section>

<div className="py-5 service-12">
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <h4 className="my-3">Votre enfant aime jouer et inventer des histoires ? Inscrivez-le à notre atelier de théâtre pour enfants.</h4>
        <h6 className="font-weight-light subtitle">C’est une activité idéale pour développer la créativité, la confiance en soi et les compétences sociales.</h6>
        <div className="row">
          <div className="col-md-6 mt-3">
            <h6 className="font-weight-medium">Créativité Éveillée</h6>
            <p>Nos ateliers de théâtre encouragent les enfants à explorer leur imagination et à exprimer leur créativité à travers le jeu et la performance...</p>
          </div>
          <div className="col-md-6 mt-3">
            <h6 className="font-weight-medium">Confiance en Soi</h6>
            <p>Nos ateliers de théâtre encouragent les enfants à explorer leur imagination et à exprimer leur créativité à travers le jeu et la performance...</p>
          </div>
          <div className="col-md-6 mt-3">
            <h6 className="font-weight-medium">Compétences Sociales</h6>
            <p>Nos ateliers de théâtre encouragent les enfants à explorer leur imagination et à exprimer leur créativité à travers le jeu et la performance...</p>
          </div>
          <div class="col-md-6 mt-3">
            <h6 class="font-weight-medium">Satisfaction Garantie</h6>
            <p>Nos ateliers de théâtre encouragent les enfants à explorer leur imagination et à exprimer leur créativité à travers le jeu et la performance...</p>
          </div>
              <div className="col-lg-12 my-4">
                <a className="btn btn-info-gradiant btn-md border-0 text-white" href="Schedule"><span> Réserver</span></a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row wrap-service12">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 img-hover mb-4">
                    <img src={sampleImage} className="rounded img-shadow img-fluid" alt="Adult Dance" />
                  </div>
                  <div className="col-md-12 img-hover mb-4">
                    <img src={sampleImage2} className="rounded img-shadow img-fluid" alt="Kids Dance" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 uneven-box">
                <div className="row">
                  <div className="col-md-12 img-hover mb-4">
                    <img src={newImage} className="rounded img-shadow img-fluid" alt="Birthday Party" />
                  </div>
                  <div className="col-md-12 img-hover mb-4">
                    <img src={sampleImage} className="rounded img-shadow img-fluid" alt="Adult Dance" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Pricing Section */}
    <div className="pricing8 py-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8 text-center">
        <h3 className="mb-3">Tarification pour rendre votre travail efficace</h3>
        <h6 className="subtitle font-weight-normal">Nous offrons une satisfaction à 100% et une garantie de remboursement</h6>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-md-4 ml-auto pricing-box align-self-center">
        <div className="card mb-4">
          <div className="card-body p-4 text-center">
            <h5 className="font-weight-normal">Carte 10 cours</h5>
            <sup>€</sup><span className="text-dark display-5">200</span>
            <h6 className="font-weight-light font-14">TARIF UNIQUE</h6>
            <p className="mt-4">Accédez à 10 cours de votre choix. Idéal pour ceux qui veulent un engagement flexible.</p>
          </div>
          <a className="btn btn-info-gradiant p-3 btn-block border-0 text-white" href="Schedule">CHOISIR</a>
        </div>
      </div>
      <div className="col-md-4 ml-auto pricing-box align-self-center">
        <div className="card mb-4">
          <div className="card-body p-4 text-center">
            <h5 className="font-weight-normal">Cours individuel</h5>
            <sup>€</sup><span className="text-dark display-5">22</span>
            <h6 className="font-weight-light font-14">PAR COURS</h6>
            <p className="mt-4">Un cours unique pour découvrir notre programme. Parfait pour les nouveaux venus.</p>
          </div>
          <a className="btn btn-danger-gradiant p-3 btn-block border-0 text-white" href="Schedule">CHOISIR</a>
        </div>
      </div>
      <div className="col-md-4 ml-auto pricing-box align-self-center">
        <div className="card mb-4">
          <div className="card-body p-4 text-center">
            <h5 className="font-weight-normal">Abonnement annuel</h5>
            <sup>€</sup><span className="text-dark display-5">520</span>
            <h6 className="font-weight-light font-14">1 COURS/SEMAINE</h6>
            <p className="mt-4">Accédez à un cours par semaine pendant un an. Idéal pour une pratique régulière.</p>
          </div>
          <a className="btn btn-info-gradiant p-3 btn-block border-0 text-white" href="Schedule">CHOISIR</a>
        </div>
      </div>
    </div>
  </div>
</div>


    {/* Service 26 Section */}
    <div className="py-5 service-26">
  <div className="container">
    <div class="row wrap-service-26">
      <div class="col-md-7 align-self-center">
        <div class="max-box">
          <h3 class="mt-3">Découvrez les Bienfaits du Théâtre pour les Enfants</h3>
          <p class="mt-3">Nos ateliers de théâtre pour enfants offrent une expérience enrichissante et amusante, parfaite pour ceux qui souhaitent explorer l'art dramatique. Nos animateurs professionnels sont là pour guider les enfants à chaque étape.</p>
          <p>Nous garantissons une progression rapide et un grand plaisir à chaque séance de théâtre.</p>
          <a href="javascript:void(0)" class="linking">En savoir plus</a>
        </div>
      </div>
      <div class="col-md-5">
        <img src={service26Image1} class="img-fluid" alt="Théâtre Enfants" />
      </div>
    </div>
    <div class="row wrap-service-26 mt-4 pt-3">
      <div class="col-md-6">
        <img src={service26Image2} class="img-fluid" alt="Techniques de Théâtre Enfants" />
      </div>
      <div class="col-md-6 align-self-center">
        <h3 class="mt-3">Perfectionnez les Talents de vos Enfants avec le Théâtre</h3>
        <p class="mt-3">Nos ateliers sont conçus pour offrir une immersion totale dans l'univers du théâtre. Les enfants bénéficieront d'un accompagnement personnalisé pour améliorer leurs compétences en jeu, leur confiance et leur créativité.</p>
        <p>Rejoignez-nous et découvrez la joie et l'expression à travers le théâtre pour enfants.</p>
        <a class="linking" href="javascript:void(0)">En savoir plus</a>
      </div>
    </div>
  </div>

<div class="cards">
     
       <div class="services">
           
          <div class="content content-2">
             <div class="fab fa-instagram"></div>
             <h2>
                Instagram
             </h2>
             <p>
             Découvrez le monde visuel vibrant de l'Espace Balard sur Instagram. Suivez-nous pour des photos magnifiques, des aperçus des coulisses et du contenu créatif qui met en valeur notre atmosphère unique et nos événements. Soyez inspiré par nos histoires et connectez-vous avec nous à travers vos clichés préférés              </p>
             <a
                  href="https://www.instagram.com/espacebalard_fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Découvrir
                </a>           </div>
          <div class="content content-3">
             <div class="fab fa-youtube"></div>
             <h2>
                Youtube
             </h2>
             <p>
             Plongez dans le contenu vidéo dynamique de l'Espace Balard sur YouTube. Regardez des vidéos captivantes, des temps forts d'événements, des tutoriels et bien plus encore. Abonnez-vous à notre chaîne pour ne rien manquer de nos dernières publications et découvrez tout ce que nous avons à offrir. 
             </p>
             <a
                  href="https://www.youtube.com/@espacebalard2791/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Découvrir
                </a>            </div>
       </div>
    </div>
    </div>
  </div>
  
);
};
export default Atheliertheatreenfant;
