import React from 'react';
import '../styles/Category.css';
import '../styles/type.css';
import '../styles/Gallery.css';
import modernJazzVideo from '../Videos/1.mp4';
import sampleImage from '../Images/adulte (2).jpeg'; // Update the path to the image
import sampleImage2 from '../Images/kids.jpeg'; // Update the path to the image
import newImage from '../Images/birthday.jpg'; // Update the path to the image
import service26Image1 from '../Images/jazz.jpg'; // Add this line
import service26Image2 from '../Images/20191019_190517.jpg'; // Add this line
import galleryImage1 from '../Images/jazz.jpg'; // Update the path as needed
import galleryImage2 from '../Images/birth.jpg'; // Update the path as needed
import galleryImage3 from '../Images/salsa.jpg'; // Update the path as needed
import galleryImage4 from '../Images/danse gregoire.png'; // Update the path as needed
import galleryImage5 from '../Images/danse gregoire.png'; // Update the path as needed
import galleryImage6 from '../Images/Adil-Djibouli-3973-scaled-1-150x150.jpg'; // Update the path as needed
import galleryImage7 from '../Images/Anne-sophie-150x150.jpg'; // Update the path as needed
import galleryImage8 from '../Images/birth.jpg'; // Update the path as needed


const Dansedumonde = () => {
  return (
    <div className="category-page">
      <section className="hero-section">
        <video className="hero-video" autoPlay muted loop>
          <source src={modernJazzVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-overlay">
  <h1 className="hero-title">Danse du Monde</h1>
  <p className="hero-description">Explorez la diversité culturelle et l'expression artistique à travers la Danse du Monde pour adultes.</p>
</div>
</section>
<div className="py-5 service-12">
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <h4 className="my-3">Vous aimez découvrir de nouvelles cultures à travers la danse ? Inscrivez-vous aux cours de Danse du Monde pour adultes.</h4>
        <h6 className="font-weight-light subtitle">C’est une activité idéale pour se divertir et apprendre des danses de différentes cultures.</h6>
        <div className="row">
          <div className="col-md-6 mt-3">
            <h6 className="font-weight-medium">Exploration Immédiate</h6>
            <p>La Danse du Monde est une forme d'art qui combine des éléments de différentes traditions culturelles...</p>
          </div>
          <div className="col-md-6 mt-3">
            <h6 className="font-weight-medium">Diversité Culturelle</h6>
            <p>Découvrez des danses du monde entier et enrichissez votre connaissance des cultures diverses...</p>
          </div>
          <div class="col-md-6 mt-3">
            <h6 class="font-weight-medium">Bienfaits Multiples</h6>
            <p>Améliorez votre forme physique tout en explorant des danses traditionnelles et contemporaines...</p>
          </div>
          <div class="col-md-6 mt-3">
            <h6 class="font-weight-medium">Satisfaction Garantie</h6>
            <p>Profitez d'une expérience enrichissante avec nos cours de Danse du Monde, satisfaits ou remboursés...</p>
          </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row wrap-service12">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12 img-hover mb-4">
                      <img src={sampleImage} className="rounded img-shadow img-fluid" alt="Adult Dance" />
                    </div>
                    <div className="col-md-12 img-hover mb-4">
                      <img src={sampleImage2} className="rounded img-shadow img-fluid" alt="Kids Dance" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 uneven-box">
                  <div className="row">
                    <div className="col-md-12 img-hover mb-4">
                      <img src={newImage} className="rounded img-shadow img-fluid" alt="Birthday Party" />
                    </div>
                    <div className="col-md-12 img-hover mb-4">
                      <img src={sampleImage} className="rounded img-shadow img-fluid" alt="Adult Dance" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div className="pricing8 py-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8 text-center">
        <h3 className="mb-3">Tarification pour rendre votre travail efficace</h3>
        <h6 className="subtitle font-weight-normal">Nous offrons une satisfaction à 100% et une garantie de remboursement</h6>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-md-4 ml-auto pricing-box align-self-center">
        <div className="card mb-4">
          <div className="card-body p-4 text-center">
            <h5 className="font-weight-normal">Carte 10 cours</h5>
            <sup>€</sup><span className="text-dark display-5">200</span>
            <h6 className="font-weight-light font-14">TARIF UNIQUE</h6>
            <p className="mt-4">Accédez à 10 cours de votre choix. Idéal pour ceux qui veulent un engagement flexible.</p>
          </div>
          <a className="btn btn-info-gradiant p-3 btn-block border-0 text-white" href="Schedule">CHOISIR</a>
        </div>
      </div>
      <div className="col-md-4 ml-auto pricing-box align-self-center">
        <div className="card mb-4">
          <div className="card-body p-4 text-center">
            <h5 className="font-weight-normal">Cours individuel</h5>
            <sup>€</sup><span className="text-dark display-5">22</span>
            <h6 className="font-weight-light font-14">PAR COURS</h6>
            <p className="mt-4">Un cours unique pour découvrir notre programme. Parfait pour les nouveaux venus.</p>
          </div>
          <a className="btn btn-danger-gradiant p-3 btn-block border-0 text-white" href="Schedule">CHOISIR</a>
        </div>
      </div>
      <div className="col-md-4 ml-auto pricing-box align-self-center">
        <div className="card mb-4">
          <div className="card-body p-4 text-center">
            <h5 className="font-weight-normal">Abonnement annuel</h5>
            <sup>€</sup><span className="text-dark display-5">520</span>
            <h6 className="font-weight-light font-14">1 COURS/SEMAINE</h6>
            <p className="mt-4">Accédez à un cours par semaine pendant un an. Idéal pour une pratique régulière.</p>
          </div>
          <a className="btn btn-info-gradiant p-3 btn-block border-0 text-white" href="Schedule">CHOISIR</a>
        </div>
      </div>
    </div>
  </div>
</div>


      {/* Service 26 Section */}
      <div className="py-5 service-26">
  <div className="container">
    <div className="row wrap-service-26">
      <div className="col-md-7 align-self-center">
        <div className="max-box">
          <h3 className="mt-3">Découvrez des Danses du Monde et enrichissez votre vie</h3>
          <p className="mt-3">Vous pouvez compter sur notre liste impressionnante de danses et nos services clients exceptionnels pour une expérience de danse inoubliable et de grande qualité.</p>
          <p>Nous vous garantissons que vous découvrirez des danses du monde entier en un temps record et que vous prendrez plaisir à apprendre.</p>
          <a href="javascript:void(0)" className="linking">En Savoir Plus</a>
        </div>
      </div>
      <div className="col-md-5">
        <img src={service26Image1} className="img-fluid" alt="Danse du Monde Feature 1" />
      </div>
    </div>
    <div className="row wrap-service-26 mt-4 pt-3">
      <div className="col-md-6">
        <img src={service26Image2} className="img-fluid" alt="Danse du Monde Feature 2" />
      </div>
      <div className="col-md-6 align-self-center">
        <h3 className="mt-3">Obtenez les meilleurs cours de Danse du Monde pour un apprentissage moderne</h3>
        <p className="mt-3">Vous pouvez compter sur notre liste impressionnante de danses et nos services clients exceptionnels pour une expérience de danse inoubliable et de grande qualité.</p>
        <p>Nous vous garantissons que vous découvrirez des danses du monde entier en un temps record et que vous prendrez plaisir à apprendre.</p>
        <a class="linking" href="javascript:void(0)">En Savoir Plus</a>
      </div>
    </div>
  </div>
   
<div class="cards">
       
         <div class="services">
            
            <div class="content content-2">
               <div class="fab fa-instagram"></div>
               <h2>
                  Instagram
               </h2>
               <p>
               Découvrez le monde visuel vibrant de l'Espace Balard sur Instagram. Suivez-nous pour des photos magnifiques, des aperçus des coulisses et du contenu créatif qui met en valeur notre atmosphère unique et nos événements. Soyez inspiré par nos histoires et connectez-vous avec nous à travers vos clichés préférés 
               </p>
               <a
                  href="https://www.instagram.com/espacebalard_fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Découvrir
                </a>             </div>
            <div class="content content-3">
               <div class="fab fa-youtube"></div>
               <h2>
                  Youtube
               </h2>
               <p>


               Plongez dans le contenu vidéo dynamique de l'Espace Balard sur YouTube. Regardez des vidéos captivantes, des temps forts d'événements, des tutoriels et bien plus encore. Abonnez-vous à notre chaîne pour ne rien manquer de nos dernières publications et découvrez tout ce que nous avons à offrir.               </p>
               <a
                  href="https://www.youtube.com/@espacebalard2791/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Découvrir
                </a>              </div>
         </div>
      </div>
      </div>
    </div>
    
  );
};

export default Dansedumonde;
