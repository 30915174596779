import React, { useState } from 'react';
import ScheduleDay from '../components/ScheduleDay';
import '../styles/Schedule.css';
import SlotDetailsModal from '../components/SlotDetailsModal';

const days = [
  { name: 'LUN', date: '24', slots: [
    { time: '15:00', duration: '60 min', course: 'Chant', instructor: 'Anne-Sophie', description: 'Cours de chant pour tous les niveaux. Apprenez à maîtriser votre voix et à chanter avec confiance. Techniques de respiration, posture et interprétation incluses.' },
    { time: '12:15', duration: '60 min', course: 'Pilate', instructor: '', description: ' Adapté à tous les niveaux.' },
    { time: '19:15', duration: '60 min', course: 'Bachata', instructor: 'Gabrielé', description: 'Cours de salsa cubaine pour débutants. Apprenez les pas de base et les mouvements de la salsa cubaine. Améliorez votre rythme et votre coordination.' },
    { time: '20:15', duration: '60 min', course: 'Bachata', instructor: 'Gabrielé', description: 'Cours de salsa cubaine de niveau intermédiaire. Perfectionnez vos compétences en salsa avec des mouvements plus complexes et des techniques avancées.' },
    { time: '17:00', duration: '60 min', course: 'Théâtre Enfants', instructor: 'Zakia', description: 'Atelier de théâtre pour les enfants de 5 à 6 ans. Développez la créativité, la confiance en soi et les compétences en communication à travers des jeux de rôle et des exercices.' },
    { time: '18:00', duration: '60 min', course: 'Théâtre Enfants', instructor: 'Zakia', description: 'Atelier de théâtre pour les enfants de 10 à 12 ans. Développez la créativité, la confiance en soi et les compétences en communication à travers des jeux de rôle et des exercices.' },
    { time: '17:00', duration: '60 min', course: 'Chant Enfants', instructor: 'Anne-Sophie ', description: 'Cours de chant pour les enfants de 7 ans et plus. Techniques de chant adaptées aux jeunes chanteurs, incluant des exercices amusants et interactifs.' },
  ]},
  { name: 'MAR', date: '25', slots: [
    { time: '14:30', duration: '2h', course: 'Théâtre', instructor: 'ZAKIAA', description: 'Cours de théâtre pour tous les niveaux. Exploration de techniques d\'acteur, improvisation et développement de personnages. Préparation à des performances scéniques.' },
    { time: '17:00', duration: '60 min', course: 'Théâtre Enfants', instructor: 'ZAKIA', description: 'Atelier de théâtre pour les enfants de 5 à 6 ans. Encouragement de l\'expression personnelle et de la coopération à travers des jeux théâtraux.' },
    { time: '12:15', duration: '60 min', course: 'Danse talon', instructor: 'Julie', description: 'Cours de danse modern jazz de niveau 3. Travail sur la technique, la musicalité et l\'interprétation. Chorégraphies dynamiques et créatives.' },
    { time: '18:15', duration: '60 min', course: 'Yoga', instructor: 'Julie', description: 'Cours de danse modern jazz de niveau 2. Amélioration de la technique et du style. Apprentissage de chorégraphies complexes.' },
    { time: '12:15', duration: '60 min', course: 'Gym douce', instructor: 'Julie', description: 'Cours de danse modern jazz de niveau 2. Amélioration de la technique et du style. Apprentissage de chorégraphies complexes.' },

    { time: '17:00', duration: '60 min', course: 'Danse Modern Jazz Enfants', instructor: 'Julie', description: 'Cours de danse modern jazz pour les enfants de 7 à 9 ans. Introduction aux bases du modern jazz avec des mouvements amusants et adaptés.' },
    { time: '18:00', duration: '60 min', course: 'Danse Modern Jazz Enfants', instructor: 'Julie', description: 'Cours de danse modern jazz pour les enfants de 5 à 6 ans. Initiation à la danse avec des exercices ludiques et éducatifs.' },
  ]},
  { name: 'MER', date: '26', slots: [
    { time: '19:00', duration: '60 min', course: 'Danse Modern Jazz', instructor: 'Julie', description: 'Cours de danse modern jazz de niveau 1. Techniques de base, expression corporelle et chorégraphies simples.' },
    { time: '12:45', duration: '60 min', course: 'Danse Modern Jazz', instructor: 'Julie', description: 'Cours de danse modern jazz de niveau 3. Travail intensif sur la technique et la performance. Chorégraphies élaborées.' },
    { time: '12:15', duration: '60 min', course: 'Danse du Monde', instructor: 'Instructor K', description: 'Cours de danse du monde (Reggaeton, Street Jazz, Afro, Bollywood). Exploration de différents styles de danse et apprentissage de chorégraphies variées.' },
    { time: '10:30', duration: '90 min', course: 'Théâtre Enfants', instructor: 'Zakia', description: 'Atelier de théâtre pour les enfants de 8 à 10 ans. Techniques de jeu, improvisation et préparation de scènes.' },
    { time: '14:00', duration: '90 min', course: 'Théâtre Enfants', instructor: 'Zakia', description: 'Atelier de théâtre pour les enfants de 9 à 12 ans. Développement des compétences théâtrales à travers des exercices et des jeux de rôle.' },
    { time: '15:30', duration: '90 min', course: 'Théâtre Enfants', instructor: 'Zakia', description: 'Atelier de théâtre pour les enfants de 9 à 12 ans. Développement des compétences théâtrales à travers des exercices et des jeux de rôle.' },
    { time: '17:00', duration: '60 min', course: 'Danse Modern Jazz Enfants', instructor: 'Instructor M', description: 'Cours de danse modern jazz pour les enfants de 7 à 9 ans. Introduction aux bases du modern jazz avec des mouvements amusants et adaptés.' },
    { time: '18:00', duration: '75 min', course: 'Danse Modern Jazz Enfants', instructor: 'Instructor M', description: 'Cours de danse modern jazz pour les adolescents. Techniques avancées et préparation de chorégraphies pour des spectacles.' },
    { time: '18:00', duration: '75 min', course: 'HIP HOP', instructor: '', description: 'Débutant' },
    { time: '12:15', duration: '60 min', course: 'Danse du monde', instructor: '', description: 'Débutant' },

  ]},
  { name: 'JEU', date: '27', slots: [
    { time: '20:00', duration: '3h', course: 'Théâtre', instructor: 'ZAKIAA', description: 'Cours de théâtre pour tous les niveaux. Exploration de techniques d\'acteur, improvisation et développement de personnages. Préparation à des performances scéniques.' },
    { time: '19:00', duration: '60 min', course: 'Danse Orientale', instructor: 'Loundjah', description: 'Cours de danse orientale pour débutants. Apprenez les mouvements de base et les techniques de la danse orientale. Améliorez votre grâce et votre coordination.' },
    { time: '17:00', duration: '60 min', course: 'Danse Classique Enfants', instructor: 'Instructor Q', description: 'Cours de danse classique pour les enfants de 7 à 9 ans. Introduction aux bases du ballet avec des exercices ludiques et éducatifs.' },
    { time: '18:00', duration: '60 min', course: 'Danse Classique Enfants', instructor: 'Instructor Q', description: 'Cours de danse classique pour les enfants de 5 à 6 ans. Initiation au ballet avec des exercices amusants et adaptés aux jeunes enfants.' },
  ]},
  { name: 'VEN', date: '28', slots: [
    { time: '19:15', duration: '60 min', course: 'Salsa Cubaine', instructor: 'Gabrielé', description: 'Cours de salsa cubaine pour débutants. Apprenez les pas de base et les mouvements de la salsa cubaine. Améliorez votre rythme et votre coordination.' },
    { time: '20:15', duration: '60 min', course: 'Salsa Cubaine', instructor: 'Gabrielé', description: 'Cours de salsa cubaine de niveau intermédiaire. Perfectionnez vos compétences en salsa avec des mouvements plus complexes et des techniques avancées.' },
    { time: '17:00', duration: '60 min', course: 'Théâtre Enfants', instructor: 'ZAKIA', description: 'Atelier de théâtre pour les enfants de 8 à 10 ans. Techniques de jeu, improvisation et préparation de scènes.' },
    { time: '18:00', duration: '60 min', course: 'Théâtre Enfants', instructor: 'ZAKIA', description: 'Atelier de théâtre pour les adolescents. Techniques avancées d\'interprétation et de mise en scène. Préparation de scènes pour des spectacles.' },
    { time: '18:00', duration: '60 min', course: 'Hip Hop Enfants', instructor: 'ZAKIA', description: 'Cours de hip hop pour les enfants de 7 ans et plus. Apprentissage des bases du hip hop avec des mouvements et des chorégraphies adaptés aux jeunes danseurs.' },
    { time: '18:00', duration: '60 min', course: 'Chant Enfants', instructor: 'Anne-Sophie ', description: 'Cours de chant pour les enfants de 7 ans et plus. Techniques de chant adaptées aux jeunes chanteurs, incluant des exercices amusants et interactifs.' },

  ]},
  { name: 'SAM', date: '29', slots: [
    { time: '09:15', duration: '60 min', course: 'Yoga', instructor: 'Annick', description: 'Séance de yoga pour la détente et le bien-être. Postures, méditation et techniques de respiration. Adapté à tous les niveaux.' },
    { time: '12:45', duration: '60 min', course: 'Danse Orientale', instructor: 'Loundjah', description: 'Cours de danse orientale pour débutants. Apprenez les mouvements de base et les techniques de la danse orientale. Améliorez votre grâce et votre coordination.' },
    { time: '11:15', duration: '75 min', course: 'Théâtre Enfants', instructor: 'ZAKIA', description: 'Atelier de théâtre pour les enfants de 5 à 6 ans. Développez la créativité, la confiance en soi et les compétences en communication à travers des jeux de rôle et des exercices.' },
    { time: '14:15', duration: '90 min', course: 'Théâtre Enfants', instructor: 'ZAKIA', description: 'Atelier de théâtre pour les enfants de 8 à 10 ans. Techniques de jeu, improvisation et préparation de scènes.' },
    { time: '15:45', duration: '90 min', course: 'Théâtre Enfants', instructor: 'ZAKIA', description: 'Atelier de théâtre pour les adolescents. Techniques avancées d\'interprétation et de mise en scène. Préparation de scènes pour des spectacles.' },
    { time: '10:15', duration: '60 min', course: 'Danse chorégraphie', instructor: 'Instructor X', description: 'Atelier de chorégraphie pour les enfants de 5 à 6 ans. Apprenez à créer et interpréter vos propres chorégraphies. Exercices de créativité et de technique.' },
    { time: '16:00', duration: '60 min', course: 'Danse chorégraphie', instructor: 'Instructor X', description: 'Atelier de chorégraphie pour les enfants de 4 à 5 ans. Introduction à la création de chorégraphies avec des exercices amusants et interactifs.' },
    { time: '17:00', duration: '60 min', course: 'Hip Hop Enfants', instructor: 'Adil', description: 'Cours de hip hop pour les enfants de 7 ans et plus. Apprentissage des bases du hip hop avec des mouvements et des chorégraphies adaptés aux jeunes danseurs.' },
    { time: '18:00', duration: '60 min', course: 'Hip Hop Enfants', instructor: 'Adil', description: 'Cours de hip hop pour les adolescents. Techniques avancées et chorégraphies dynamiques. Préparation de spectacles.' },
  ]},
];

function Schedule() {
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSlot, setSelectedSlot] = useState(null);

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
  };

  const handleCloseModal = () => {
    setSelectedSlot(null);
  };

  const filteredDays = days.map(day => ({
    ...day,
    slots: day.slots
      .filter(slot => !selectedCourse || slot.course === selectedCourse)
      .sort((a, b) => a.time.localeCompare(b.time))
  }));

  const uniqueCourses = [...new Set(days.flatMap(day => day.slots.map(slot => slot.course)))];

  return (
    <div className="schedule-container">
      <div className="planning-header">
        <h1>PLANNING</h1>
        <div className="filter">
          <label htmlFor="course">Scéance:</label>
          <select id="course" value={selectedCourse} onChange={handleCourseChange}>
            <option value="">Toutes les séances</option>
            {uniqueCourses.map(course => (
              <option key={course} value={course}>{course}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="schedule">
        {filteredDays.map(day => (
          <ScheduleDay key={day.date} day={day} onSlotClick={handleSlotClick} />
        ))}
      </div>
      {selectedSlot && <SlotDetailsModal slot={selectedSlot} onClose={handleCloseModal} />}
    </div>
  );
}

export default Schedule;