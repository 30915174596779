import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { auth, db, storage } from '../components/firebase';  // Import firebase configuration
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const defaultTheme = createTheme();

export default function SignUp() {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [medicalCertificateFile, setMedicalCertificateFile] = useState(null);

  const handleFileChange = (event) => {
    setMedicalCertificateFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email');
    const password = data.get('password');
    const firstName = data.get('firstName');
    const lastName = data.get('lastName');
    const telephone = data.get('telephone');
    const address = data.get('address');
    const dob = data.get('dob');
    const emergencyContact = data.get('emergencyContact');
    const emergencyEmail = data.get('emergencyEmail');
    const emergencyPhone = data.get('emergencyPhone');
    const healthInfo = data.get('healthInfo');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      let medicalCertificateURL = '';
      if (medicalCertificateFile) {
        const storageRef = ref(storage, `medicalCertificates/${user.uid}/${medicalCertificateFile.name}`);
        await uploadBytes(storageRef, medicalCertificateFile);
        medicalCertificateURL = await getDownloadURL(storageRef);
      }

      const userDoc = {
        email,
        firstName,
        lastName,
        telephone,
        address,
        dob,
        emergencyContact,
        emergencyEmail,
        emergencyPhone,
        healthInfo,
        medicalCertificateURL,
        createdAt: serverTimestamp(),
      };

      await addDoc(collection(db, 'users'), userDoc);

      setSuccess('Registration successful!');
      setError('');
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    } catch (err) {
      setError(err.message);
      setSuccess('');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Inscription
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
          {success && <Typography color="primary">{success}</Typography>}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Prénom"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Nom de famille"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Adresse email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="password"
                  label="Mot de passe"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="telephone"
                  label="Téléphone"
                  name="telephone"
                  autoComplete="tel"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Adresse postale"
                  name="address"
                  autoComplete="address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="dob"
                  label="Date de naissance"
                  name="dob"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="emergencyContact"
                  label="Personne à prévenir en cas d’urgence"
                  name="emergencyContact"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="emergencyEmail"
                  label="Adresse email de la personne à prévenir"
                  name="emergencyEmail"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="emergencyPhone"
                  label="Téléphone de la personne à prévenir"
                  name="emergencyPhone"
                  autoComplete="tel"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="healthInfo"
                  label="Informations santé (Merci de préciser si vous rencontrez des problèmes médicaux)"
                  name="healthInfo"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Certificat médical - Merci de nous transmettre votre certificat médical ou en main propre
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                >
                  Télécharger un fichier
                  <input
                    type="file"
                    hidden
                    name="medicalCertificate"
                    id="medicalCertificate"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="Je souhaite recevoir des inspirations, des promotions marketing et des mises à jour par email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              S'inscrire
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="Login" variant="body2">
                  Vous avez déjà un compte ? Se connecter
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
