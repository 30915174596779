// src/components/ScheduleDay.js
import React from 'react';
import '../styles/Schedule.css';
import { useNavigate } from 'react-router-dom';
import { db } from '../components/firebase'; // Import Firestore instance
import { collection, addDoc } from 'firebase/firestore';

function ScheduleDay({ day }) {
  const navigate = useNavigate();

  const handleReserveClick = async (slot) => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) {
        console.log('User not logged in. Redirecting to login page.');
        window.location.href = '/login';
        return;
      }

      const reservationDetails = {
        email: user.email,
        course: slot.course,
        time: slot.time,
        duration: slot.duration,
        instructor: slot.instructor,
      };

      console.log('Reservation Details:', reservationDetails);

      // Store reservation details in Firestore
      const docRef = await addDoc(collection(db, 'reservations'), reservationDetails);
      console.log('Document written with ID: ', docRef.id);

      alert('Reservation successful');

      // Redirect to the payment page
      navigate('/payment');
    } catch (error) {
      console.error('Error making reservation:', error);
      alert('Failed to make reservation');
    }
  };

  return (
    <div className="schedule-day">
      <div className="day-header">
        <span>{day.name}.{day.date}</span>
      </div>
      {day.slots.map((slot, index) => (
        <div key={index} className="schedule-slot">
          <div className="time">{slot.time}</div>
          <div className="details">
            <div className="duration">{slot.duration}</div>
            <div className="course">{slot.course}</div>
            <div className="instructor">{slot.instructor}</div>
          </div>
          <button className="reserve-button" onClick={() => handleReserveClick(slot)}>réserver</button>
        </div>
      ))}
    </div>
  );
}

export default ScheduleDay;
