import React from 'react';
import '../styles/Home.css';  // Ensure the path to Home.css is correct
import sampleImage2 from '../Images/kids.jpeg'; // Update the path to the image
import sampleImage from '../Images/adulte (2).jpeg'; // Update the path to the image
import newImage from '../Images/birthday.jpg'; // Update the path to the new image

const Home = () => {
  return (
    <div className="home">
      <div className="section">
        <div className="text-container">
          <h1>Découvrez Nos Activités Enrichissantes pour Adultes à l'Espace Balard!</h1>
          <p>
            <br></br> <br></br> 
            Découvrez les activités passionnantes pour adultes à l'Espace Balard ! <br></br><br></br> 
            Plongez dans le bien-être avec nos cours de Yoga, où vous pourrez améliorer votre flexibilité, votre force et votre sérénité intérieure.<br></br> <br></br> 
            Exprimez votre créativité et développez votre confiance en vous grâce à notre Théâtre pour adultes. <br></br> <br></br> 
            Laissez-vous envoûter par les mouvements gracieux et expressifs de la Danse Orientale, ou perfectionnez votre voix avec nos cours de Chant. <br></br> <br></br> 
            Rejoignez la dynamique Salsa Cubaine pour des moments de danse rythmée et énergique, ou optez pour la Gym Douce, idéale pour renforcer vos muscles tout en douceur et améliorer votre mobilité. Que vous soyez débutant ou confirmé, nos activités sont conçues pour vous offrir des expériences enrichissantes et inoubliables.<br></br><br></br>  
            Venez découvrir l'Espace Balard et enrichissez votre quotidien avec nos activités variées et stimulantes !<br></br> <br></br> 
          </p>
          <div className="buttons-container">
            <a href="Schedule" className="home-btn">Découvrir</a>
            <a href="Schedule" className="home-btn1">Réserver</a>
          </div>
        </div>
        <div className="image-container">
          <a href="/schedule">
          <img src={sampleImage} alt="Activities" href="/schedule" />
          </a> 
        </div>
      </div>
      
      <div className="section">
        <div className="image-container">
        <a href="/schedule">

          <img src={sampleImage2} alt="Activities" />
          </a> 
        </div>
        <div className="text-container">
          <h1>Des Activités Passionnantes pour Enfants à l'Espace Balard!</h1>
          <p>
            <br></br> <br></br>
            Découvrez les activités enrichissantes pour enfants à l'Espace Balard !<br></br> <br></br> 
            Faites danser vos enfants avec la grâce et la technique de la Danse Classique, ou laissez-les s'exprimer au rythme dynamique du Hip Hop. <br></br> <br></br> 
            Nos cours de Chant offrent une opportunité parfaite pour développer leur voix et leur amour de la musique. <br></br> <br></br> 
            Les Ateliers Théâtre stimulent leur imagination et leur confiance en eux à travers l'interprétation et la mise en scène.<br></br> <br></br> 
            Pour les amateurs de danse contemporaine, la Danse Moderne Jazz combine énergie et expression artistique, tandis que les Ateliers Chorégraphie permettent à vos enfants de créer et de partager leurs propres mouvements et danses.<br></br> <br></br> 
            Chaque activité est conçue pour favoriser leur développement personnel, leur créativité et leur bien-être. <br></br> <br></br> 
            Inscrivez vos enfants à l'Espace Balard et offrez-leur des expériences mémorables et épanouissantes !<br></br> <br></br> 
          </p>
          <div className="buttons-container">
            <a href="Schedule" className="home-btn">Découvrir</a>
            <a href="Schedule" className="home-btn1">Réserver</a>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="text-container3">
          <h1>Organisez Votre Événement: Anniversaire ou Location d'Espace! </h1>
          <p>
            <br></br> <br></br>
            Faites de votre événement un moment inoubliable à l'Espace Balard ! Que vous souhaitiez célébrer un anniversaire mémorable ou louer notre espace polyvalent pour des occasions spéciales, nous sommes là pour répondre à tous vos besoins. Notre salle spacieuse et bien équipée est idéale pour accueillir des fêtes, des réunions familiales, des soirées dansantes, et bien plus encore.
  <br></br> <br></br>
  Profitez d'un cadre élégant et convivial, doté de toutes les commodités nécessaires pour garantir le succès de votre événement. Nous offrons un service personnalisé pour vous aider à organiser chaque détail, de la décoration à la musique, en passant par les options de restauration. Laissez-vous séduire par notre espace lumineux, notre tapis de danse professionnel, et nos installations modernes.<br></br> <br></br>
  Que ce soit pour une petite réunion intime ou une grande célébration, l'Espace Balard s'adapte à vos besoins et vous promet des moments inoubliables. Contactez-nous dès aujourd'hui pour discuter de vos idées, visiter notre espace, et réserver la date parfaite pour votre événement. Transformez vos rêves en réalité à l'Espace Balard !.<br></br> <br></br>
          </p>
          <div className="buttons-container">
            <a href="Studio" className="home-btn">Explorer</a>
            <a href="Studio" className="home-btn1">S'inscrire</a>
          </div>
        </div>
        <div className="image-container">
          <img src={newImage} alt="New Activities" />
        </div>
      </div>
      
      <div className="map-section">
        <div className="map-container">
          <iframe
            title="Espace Balard Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.7395857637357!2d2.278579011726066!3d48.836780211230696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6707347f760b9%3A0xb3a1447cbe834816!2sESPACE%20BALARD!5e0!3m2!1sfr!2sfr!4v1716230388340!5m2!1sfr!2sfr"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="map-description">
          <h2>ESPACE BALARD</h2>
          <p>
            Découvrez notre Salle de Danse Événementielle à Paris 15 : Votre Espace Créatif Privé ! 🎶
Superficie: 80m2 Situation: Rez-de-chaussée Sol : Tapis de danse Vestiaires et sanitaires System d’aération 
            <br />
            354 Rue Lecourbe , Paris, 75015
            <br />
            transport public pour y arriver a compléter
          </p>
          <a href="Studio" className="home-btn">DÉCOUVRIR LE STUDIO</a>
        </div>
      </div>
    </div>
  );
};

export default Home;
