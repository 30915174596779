import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import '../styles/Category.css';
import anniversaireVideo from '../Videos/4.mp4';

// Import your images
import image1 from '../Images/HALLOWEN HORIZONTAL.jpg';
import image2 from '../Images/20220707_190525.jpg';
import image3 from '../Images/birth.jpg';
import image4 from '../Images/birthday.jpg';
import image5 from '../Images/HALLOWEN HORIZONTAL.jpg';
import image6 from '../Images/adulte (2).jpeg';
import image7 from '../Images/adulte (2).jpeg';

// Add more imports as needed

const itemData = [
  { img: image1, title: 'Halloween Party' },
  { img: image2, title: 'Aventuriers des Caraïbes – L’Île aux Pirates' },
  { img: image3, title: 'Boum Party Inoubliable à l’Espace Balard, Paris 15!' },
  { img: image4, title: 'Anniversaire Magique sous le Thème Blanche-Neige' },
  { img: image5, title: 'Anniversaire Magique sous le Thème Harry Potter' },
  // Add more images as needed
];

const themes = [
  {
    title: "Halloween Party",
    description: `
     <br> <br>La saison d’Halloween approche à grands pas, et quoi de mieux que de transformer l’anniversaire de votre enfant en une aventure mystique et effrayante ?
      Préparez-vous à plonger dans l’obscurité avec notre « Anniversaire Terrifiant – Halloween » 
      <br><strong>Décoration Époustouflante:</strong><br>
      Notre équipe de décorateurs experts transforme le studio en un lieu hanté avec des décors inquiétants et des effets spéciaux pour une immersion totale.
      <br> <strong>Chansons Effrayantes:</strong><br>
      Notre DJ professionnel jouera une sélection de chansons d’Halloween pour créer une atmosphère terrifiante. Voici quelques titres pour vous donner la chair de poule :
      <ul>
        <li>« Thriller » – Michael Jackson</li>
        <li>« Monster Mash » – Bobby Pickett</li>
        <li>« Ghostbusters » – Ray Parker Jr.</li>
        <li>« This Is Halloween » – The Nightmare Before Christmas</li>
      </ul>
      <strong>Jeux et Activités Sinistres:</strong><br>
      Nos animateurs chevronnés guideront les enfants dans des jeux et activités amusantes, comme la chasse aux bonbons, les histoires effrayantes et la création de masques monstrueux.
      <ul>
        <li><strong>Chasse aux Bonbons:</strong> Organisez une chasse aux bonbons où les enfants peuvent chercher des friandises cachées dans des endroits effrayants.</li>
        <li><strong>Histoires Effrayantes:</strong> Un conteur peut raconter des histoires adaptées à l'âge pour ajouter une touche de mystère et de frisson.</li>
        <li><strong>Atelier de Création de Masques:</strong> Les enfants peuvent fabriquer leurs propres masques de monstres avec des matériaux variés.</li>
      </ul>
    `,
  },
  {
    title: "Aventuriers des Caraïbes – L’Île aux Pirates",
    description: `
    <br><br> Ahoi, moussaillons ! Préparez-vous à embarquer pour une aventure inoubliable en haute mer.
      Votre enfant et ses amis vont se plonger dans l’univers passionnant de Jack Sparrow et de ses compagnons pirates.

      <br> <strong>Décoration :</strong><br> 
      Notre espace se transforme en une île mystérieuse de la mer des Caraïbes, avec des palmiers en carton, des tonneaux de rhum factices, des cartes au trésor et des drapeaux pirates.

      <br><strong>Costumes :</strong><br> 
      Encouragez les petits pirates à s’habiller en capitaines, matelots ou même en Jack Sparrow lui-même.

      <br><strong>Activités :</strong><br>
      <ul>
        <li><strong>Atelier de Bandeaux Pirates :</strong> Les enfants pourront personnaliser leurs propres bandeaux pirates avec des autocollants, des crayons et des peintures.</li>
        <li><strong>Chorégraphie de Danse Pirate :</strong> Pour animer la fête, notre animateur proposera une chorégraphie de danse pirate simple que les enfants adoreront :</li>
        <ul>
          <li><strong>Le Pas de la Planche :</strong> Les enfants marchent lentement comme s’ils marchaient sur une planche au-dessus de l’eau. Ils peuvent même ajouter des gestes dramatiques pour équilibrer.</li>
          <li><strong>La Danse du Trésor :</strong> Les enfants creusent le sol comme s’ils cherchaient un trésor. Ils peuvent faire semblant de déterrer des pièces d’or imaginaires.</li>
          <li><strong>Le Roulement de Canon :</strong> Les enfants se mettent en ligne, placent leurs mains derrière leur oreille comme s’ils écoutaient et font un petit saut en tournant pour regarder de l’autre côté, puis répètent de l’autre côté.</li>
        </ul>
      </ul>
      Musique de pirate entraînante pour accompagner cette chorégraphie amusante et énergique. Votre enfant et ses amis auront une fête d’anniversaire de pirates des Caraïbes mémorable et remplie d’aventures!
    `,
  },
  {
    title: "Boum Party Inoubliable à l’Espace Balard, Paris 15!",
    description: `
    <br><br>Imaginez une salle remplie de lumières colorées, de ballons scintillants, de musique entraînante et de rires joyeux.
      C’est exactement ce que vous obtiendrez lorsque vous choisirez notre Boum Party pour l’anniversaire de votre enfant !


      <br><strong>Décoration Éblouissante :</strong><br> 
      Notre équipe de décorateurs talentueux transforme le studio en un paradis festif, avec des guirlandes, des ballons et des lumières qui donneront vie à la Boum Party.

      <br><strong>Chansons Entraînantes :</strong><br> 
      Notre DJ professionnel jouera une playlist de chansons préférées des enfants, des hits du moment aux classiques incontournables. Imaginez vos petits danseurs se déhanchant sur les sons de leurs artistes préférés !

      <br><strong>Animations Amusantes :</strong><br> 
      Nos animateurs expérimentés garderont les enfants engagés avec des jeux divertissants et des activités interactives. Il y aura des concours de danse, des défis musicaux et bien plus encore.

      <br><strong>Les Chansons :</strong><br>
      Voici un avant-goût de quelques-unes des chansons que nos DJ peuvent jouer pour faire vibrer la piste de danse de la Boum Party :
      <ul>
        <li>« Happy » – Pharrell Williams</li>
        <li>« Can’t Stop the Feeling! » – Justin Timberlake</li>
        <li>« Uptown Funk » – Mark Ronson ft. Bruno Mars</li>
        <li>« Let It Go » – Frozen (pour une petite pause dansante sur un thème Reine des Neiges)</li>
        <li>« Y.M.C.A. » – Village People (pour des chorégraphies hilarantes)</li>
      </ul>
    `,
  },
  {
    title: "Anniversaire Magique sous le Thème Blanche-Neige",
    description: `
    <br><br> Il est temps de faire briller les rêves de vos petits princes et princesses.

      <br><strong>Le Thème Blanche-Neige :</strong><br>
      Imaginez une salle enchantée, ornée de pommes rouges, de forêts magiques et de nains adorables.
      Votre enfant et ses amis seront transportés dans l’univers magique de Blanche-Neige, où les rêves deviennent réalité.


      <br><strong>Décoration de Conte de Fées :</strong><br> 
      Notre équipe de décorateurs expérimentés transformera le studio en une forêt enchantée, avec des guirlandes de fleurs, des nains en peluche et des citrouilles lumineuses.

      <br><strong>Chansons Magiques :</strong><br> 
      Notre DJ professionnel jouera une sélection de chansons inspirées de Blanche-Neige pour créer une atmosphère magique. Voici quelques titres enchanteresses :
      <ul>
        <li>« Un Jour Mon Prince Viendra » – Blanche-Neige</li>
        <li>« Heigh-Ho » – Les Nains</li>
        <li>« Un Sourire en Chantant » – Les Animaux de la Forêt</li>
        <li>« Boum Boum Boum » – La Méchante Reine</li>
      </ul>

      <br><strong>Jeux et Activités de Conte de Fées :</strong><br> 
      Nos animateurs professionnels divertiront les enfants avec des jeux, des histoires enchantées et des activités artistiques inspirées de l’histoire de Blanche-Neige.
    `,
  },
  {
    title: "Anniversaire Magique sous le Thème Harry Potter",
    description: `
    <br><br>  Êtes-vous prêts pour une aventure extraordinaire au cœur de l’école de sorcellerie Poudlard ?
      Rejoignez-nous pour un anniversaire enchanteur sous le thème « Harry Potter » qui se tiendra au studio Espace Balard.
      Préparez vos baguettes et vos capes, car l’école des sorciers vous attend ! 🧙‍♂️🦉✨

      <br><strong>Le Thème Harry Potter :</strong><br>
      Imaginez une salle transformée en un monde magique, avec des chouettes, des chapeaux pointus, des étoiles scintillantes et des tableaux enchantés.
      Votre enfant et ses amis seront transportés dans l’univers magique de Harry Potter.

      <br><strong>L’Organisation :</strong><br>

      <strong>Décoration Ensorcelante :</strong><br> 
      Notre équipe de décorateurs experts transformera le studio en une réplique miniature de Poudlard, avec des maisons de sorciers, des lettres volantes et des livres ensorcelés.

      <br><strong>Chansons Magiques :</strong><br> 
      Notre DJ professionnel jouera une sélection de chansons inspirées de l’univers de Harry Potter pour créer une atmosphère magique. Voici quelques titres envoûtants :
      <ul>
        <li>« Hedwig’s Theme » – John Williams</li>
        <li>« Double Trouble » – La Chorale de Poudlard</li>
        <li>« A Window to the Past » – John Williams</li>
        <li>« Weasley’s Wizard Wheezes » – Nicholas Hooper</li>
      </ul>

      <br><strong>Jeux et Activités Sorciers :</strong><br> 
      Nos animateurs professionnels divertiront les enfants avec des jeux et des activités basés sur l’univers de Harry Potter, comme la chasse aux horcruxes, le cours de sortilèges et la découverte de créatures magiques.
    `,
  },
];

const Anniversaire = () => {
  return (
    <div className="category-page">
      <section className="hero-section">
        <video className="hero-video" autoPlay muted loop>
          <source src={anniversaireVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-overlay">
          <h1 className="hero-title">Anniversaire</h1>
          <p className="hero-description">Célébrez votre anniversaire avec une fête inoubliable!</p>
        </div>
      </section>
      <section className="content-section">
        <div className="text-content">
          <h2>Une fête d’anniversaire inoubliable pour votre enfant à Paris 15 ! 🎉</h2>
          <p>
            Chers parents parisiens,
            <br /><br />
            Nous savons à quel point organiser l’anniversaire parfait pour votre enfant peut être un défi excitant, mais aussi un peu stressant. C’est pourquoi nous sommes là pour vous simplifier la vie et faire briller les yeux de votre petit trésor !
            <br /><br />
            Imaginez une fête où la joie et la danse se rejoignent pour créer des souvenirs magiques.
            À l’ESPACE BALARD Paris 15, nous faisons de chaque anniversaire un événement extraordinaire.
            Notre salle de danse vibrante est prête à accueillir votre enfant et ses amis pour une journée remplie de rires, de mouvements et de moments mémorables.
            Plus besoin de jongler avec les idées pour divertir les petits invités, notre équipe de danseurs et d’animateurs les fera tourbillonner dans la joie.
          </p>
        </div>
      </section>
      <Grid container spacing={4}>
        {themes.map((theme, index) => (
          <React.Fragment key={index}>
            {index % 2 === 0 ? (
              <>
                <Grid item xs={12} md={6}>
                  <img className="theme-image" src={itemData[index].img} alt={itemData[index].title} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="theme-section" p={2} border={1} borderColor="grey.300">
                    <Typography variant="h5" className="theme-title">{theme.title}</Typography>
                    <Typography component="div" dangerouslySetInnerHTML={{ __html: theme.description }} />
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Box className="theme-section" p={2} border={1} borderColor="grey.300">
                    <Typography variant="h5" className="theme-title">{theme.title}</Typography>
                    <Typography component="div" dangerouslySetInnerHTML={{ __html: theme.description }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <img className="theme-image" src={itemData[index].img} alt={itemData[index].title} />
                </Grid>
              </>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </div>
  );
};

export default Anniversaire;
