// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import getAuth from Firebase Auth SDK
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getStorage } from "firebase/storage"; // Import Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBRsb_TjVrCK3EYotqIiJMcxgwZSNw0jj0",
  authDomain: "espace-balard-98a3e.firebaseapp.com",
  projectId: "espace-balard-98a3e",
  storageBucket: "espace-balard-98a3e.appspot.com",
  messagingSenderId: "683314330994",
  appId: "1:683314330994:web:e2165ebb5424205f4e31eb",
  measurementId: "G-EXPER1Q285"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Initialize Firebase Authentication
const db = getFirestore(app); // Initialize Firestore
const storage = getStorage(app); // Initialize Firebase Storage

// Export the auth, db, and storage objects to use them in other parts of your application
export { auth, db, storage };
